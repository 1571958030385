import React from 'react';
import styled, { css } from 'styled-components';
import FlexWrap from './FlexWrap';

type Props = {
	title?: React.ReactNode;
	content?: React.ReactNode;
	buttons?: React.ReactNode;
	isOverflow?: boolean;
};

function ModalFrame({
	title = '제목 없음',
	content = '내용 없음',
	buttons = '버튼 없음',
	isOverflow = false,
}: Props) {
	return (
		<DimedWindow>
			<Modal isOverflow={isOverflow}>
				<Title>{title}</Title>
				<ContentWrapper>{content}</ContentWrapper>
				<BtnWrapper>{buttons}</BtnWrapper>
			</Modal>
		</DimedWindow>
	);
}

export default ModalFrame;

type StyleProps = {
	isOverflow?: boolean;
};

const DimedWindow = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 200;
`;

const Modal = styled(FlexWrap)<StyleProps>`
	width: 100%;
	max-width: 400px;
	margin: 20px;
	padding: 24px;
	flex-direction: column;
	align-items: center;
	background-color: white;
	border-radius: 8px;
	${({ isOverflow }) =>
		isOverflow &&
		css`
			height: 90%;
			overflow: auto;
		`}
`;

const Title = styled.h6`
	margin-bottom: 16px;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
`;

const ContentWrapper = styled.div`
	width: 100%;
`;

const BtnWrapper = styled.div`
	display: flex;
	gap: 8px;
	width: 100%;
	margin-top: 16px;
`;
