import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  dir?: 'row' | 'column';
  children?: ReactNode;
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  alignItems?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  flex?: string;
  margin?: string;
  onClick?: () => void;
  gap?: string;
}
function FlexWrap({ dir = 'row', children, ...divProps }: IProps) {
  return (
    <FlexContainer dir={dir} {...divProps}>
      {children}
    </FlexContainer>
  );
}
const FlexContainer = styled.div<IProps>`
  display: flex;
  flex-direction: ${(props) => props.dir};
  justify-content: space;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex: ${(props) => props.flex};
  margin: ${(props) => props.margin || 0};
  gap: ${(props) => props.gap || 0};
`;
export default FlexWrap;
