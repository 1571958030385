import styled from 'styled-components';

const ContainerLayout = styled.section`
  @media ${(props) => props.theme.desktop} {
    padding: 150px 0;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 80px 0;
  }
`;

export default ContainerLayout;
