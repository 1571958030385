export const removeLetter = (text: string) => {
  return text.replace(/[^0-9]/g, '');
};

export const comma = (x: string | number) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatRefundPrice = (
  originPrice: string,
  paymentPrice: string
) => {
  return +originPrice - +paymentPrice;
};
export const formatPaymentPrice = (num: string) => {
  const vat = Math.floor((+num / 11) * 0.7);
  return vat < 100 ? +num - vat : Math.ceil((+num - vat) / 100) * 100;
};
export const formatNumber = (num: number) => {
  return String(num).length === 1 ? `0${num}` : num;
};
