import FaqButton from 'components/_common/FaqButton';
import ModalFrame from 'components/_common/ModalFrame';
import React from 'react';
import styled from 'styled-components';
import IMG_GUIDE_1 from 'assets/cashPickup/img_guide1.png';
import IMG_GUIDE_2 from 'assets/cashPickup/img_guide2.png';
import IMG_GUIDE_3 from 'assets/cashPickup/img_guide3.png';
import IMG_GUIDE_4 from 'assets/cashPickup/img_guide4.png';

type Props = {
	onClose: () => void;
};

function CashPickupGuideModal({ onClose }: Props) {
	return (
		<ModalFrame
			isOverflow
			title={
				<TitleWrapper>
					<Title>Guide for Cash Pick up</Title>
					<SubTitle>
						Cash Pick-up is a refund method instead of remittance.
					</SubTitle>
				</TitleWrapper>
			}
			content={
				<GuideWrapper>
					<GuideText>
						<GuideImg src={IMG_GUIDE_1} alt="" />
						1. When the progress bar reached the ‘Receive Completed’ please ask
						us to get MTCN Code through ‘Inquiry’ at the main page.
					</GuideText>
					<GuideText>
						<GuideImg src={IMG_GUIDE_2} alt="" />
						2. Visit a Western Union agent location. Click{' '}
						<Link
							href="https://www.westernunion.com/global-services/find-locations?WUCountry=us&WULanguage=en"
							target="_blank"
						>
							here
						</Link>{' '}
						to find locations.
					</GuideText>
					<GuideText>
						<GuideImg src={IMG_GUIDE_3} alt="" />
						3. Fill out an application with the information you received from
						the sender. Please bring your government- issued ID.
					</GuideText>
					<GuideText>
						<GuideImg src={IMG_GUIDE_4} alt="" />
						4. Submit the application and pick up cash at the agent locations.
					</GuideText>
				</GuideWrapper>
			}
			buttons={
				<FaqButton
					status="active"
					width="100%"
					padding="10px 0"
					fontSize={16}
					onClick={onClose}
				>
					Confirm
				</FaqButton>
			}
		/>
	);
}

export default CashPickupGuideModal;

const TitleWrapper = styled.div`
	margin-bottom: 8px;
`;
const Title = styled.strong`
	display: block;
	margin-bottom: 4px;
	font-size: 22px;
`;

const SubTitle = styled.small`
	color: #3a3b3e;
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	line-height: 150%;
`;

const GuideWrapper = styled.ul`
	display: flex;
	margin-bottom: 16px;
	flex-direction: column;
	gap: 36px;
`;

const GuideImg = styled.img`
	width: 100%;
	margin-bottom: 8px;
`;

const GuideText = styled.li`
	color: #80848a;
	text-align: center;
	font-size: 14px;
	line-height: 150%; /* 21px */
	font-weight: 500;
	letter-spacing: -0.28px;
`;

const Link = styled.a`
	color: #80848a;
	text-decoration: underline;
`;
