export const JPN_CITY_LIST = [
	'Aichi',
	'Akita',
	'Aomori',
	'Chiba',
	'Ehime',
	'Fukui',
	'Fukuoka',
	'Fukushima',
	'Gifu',
	'Gunma',
	'Hiroshima',
	'Hokkaido',
	'Hyogo',
	'Ibaraki',
	'Ishikawa',
	'Iwate',
	'Kagawa',
	'Kagoshima',
	'Kanagawa',
	'Kochi',
	'Kumamoto',
	'Kyoto',
	'Mie',
	'Miyagi',
	'Miyazaki',
	'Nagano',
	'Nagasaki',
	'Nara',
	'Niigata',
	'Oita',
	'Okayama',
	'Okinawa',
	'Osaka',
	'Saga',
	'Saitama',
	'Shiga',
	'Shimane',
	'Shizuoka',
	'Tochigi',
	'Tokushima',
	'Tokyo',
	'Tottori',
	'Toyama',
	'Wakayama',
	'Yamagata',
	'Yamaguchi',
	'Yamanashi',
];
