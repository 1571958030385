import styled from 'styled-components';
import ChatBox from './ChatBox';
import { useQuery } from 'react-query';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import { getMyQnaList } from 'api';
import ICON_ALT from 'assets/common/icon_alt_blank.png';

function MyQna() {
	const { passportNumber, nationality } = useAppSelector(
		(state) => state
	) as IGlobalState;

	const { data: myQnaList = [] } = useQuery(
		['getMyQnaList', passportNumber],
		() => getMyQnaList(passportNumber!)
	);

	return (
		<Wrapper>
			{myQnaList?.length === 0 ? (
				<EmptyWrapper>
					<IconAlt src={ICON_ALT} alt="Empty" />
					<EmptyText>
						{nationality === 'KOR'
							? '작성된 문의가 없습니다.'
							: 'No inquiries have been created.'}
					</EmptyText>
				</EmptyWrapper>
			) : (
				myQnaList.map((chat) => {
					return (
						<ChatWrapper isQuestion={chat.isQuestion} key={chat.createdDate}>
							<ChatBox
								content={chat.content}
								createdDate={chat.createdDate}
								isQuestion={chat.isQuestion}
							/>
						</ChatWrapper>
					);
				})
			)}
		</Wrapper>
	);
}

export default MyQna;

type ChatStyleProps = {
	isQuestion: boolean;
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 14px;
	box-shadow: inset 0px 4px 4px 0px rgba(232, 232, 232, 0.25);
	width: 100%;
	padding: 104px 20px 24px;
	background: #fff;
`;

const ChatWrapper = styled.div<ChatStyleProps>`
	display: flex;
	justify-content: ${({ isQuestion }) =>
		isQuestion ? 'flex-end' : 'flex-start'};
`;

const EmptyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 300px;
`;

const IconAlt = styled.img`
	width: 24px;
	height: 24px;
`;

const EmptyText = styled.p`
	margin-top: 6px;
	font-size: 12px;
	font-weight: 500;
	color: #cbccce;
`;
