import ModalFrame from 'components/_common/ModalFrame';
import { useAppDispatch } from 'hooks/useReduxHooks';
import React from 'react';
import styled from 'styled-components';
import ICON_HUMAN from 'assets/common/icon_human.png';
import FaqButton from 'components/Faq/FaqButton';

type Props = {
	title: React.ReactNode;
	questionTitle: string;
	questionText: string;
	answerTitle: string;
	answerText: string;
	btnTitle: string;
};

function BankInquiryGuideModal({
	title,
	questionTitle,
	questionText,
	answerTitle,
	answerText,
	btnTitle,
}: Props) {
	const dispatch = useAppDispatch();

	const closeModal = () => {
		dispatch({
			type: 'UPDATE_HOME_MODAL_STATE',
			payload: { isActive: false, data: null, type: null },
		});
	};

	return (
		<ModalFrame
			title={<p>{title}</p>}
			content={
				<ContentsWrapper>
					<QuestionWrapper isQuestion>
						<QuestionTitle isQuestion>
							<img src={ICON_HUMAN} alt="" width={20} height={25} />
							{questionTitle}
						</QuestionTitle>
						<QuestionText isQuestion>{questionText}</QuestionText>
					</QuestionWrapper>
					<QuestionWrapper>
						<QuestionTitle>{answerTitle}</QuestionTitle>
						<QuestionText>{answerText}</QuestionText>
					</QuestionWrapper>
				</ContentsWrapper>
			}
			buttons={
				<>
					<FaqButton
						status="active"
						width="100%"
						padding="10px 0"
						fontSize={16}
						onClick={closeModal}
					>
						{btnTitle}
					</FaqButton>
				</>
			}
		/>
	);
}

export default BankInquiryGuideModal;

type StyleProps = {
	isQuestion?: boolean;
};

const ContentsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 16px;
`;

const QuestionWrapper = styled.dl<StyleProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	padding: 8px;
	border-radius: 12px;
	background-color: ${({ isQuestion }) => (isQuestion ? '#f5f6f7' : '#F4F8FF')};
`;

const QuestionTitle = styled.dt<StyleProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border-radius: 12px;
	flex: 1;
	min-width: 36px;
	padding: 4px 12px;
	color: ${({ isQuestion }) => (isQuestion ? '#80848a' : '#fff')};
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
	word-break: keep-all;
	background-color: ${({ isQuestion }) => (isQuestion ? '#e5e6e8' : '#246CF6')};
`;

const QuestionText = styled.dd<StyleProps>`
	flex: 4;
	text-align: left;
	border-radius: 12px;
	padding: 4px;
	color: ${({ isQuestion }) => (isQuestion ? '#3a3b3e' : '#246CF6')};
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
`;
