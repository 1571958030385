import styled from 'styled-components';
import { qnaData, qnaDataEng } from 'constant';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import ToggleItem from 'components/Faq/ToggleItem';

function Qna() {
	const { nationality } = useAppSelector((state) => state) as IGlobalState;

	return (
		<Wrapper>
			<Title>
				{nationality === 'KOR'
					? `문의하시기 전에\n질문들을 확인해보세요`
					: 'FAQ'}
			</Title>
			{(nationality === 'KOR' ? qnaData : qnaDataEng).map((data, idx) => (
				<ToggleItem key={idx} id={idx} {...data} />
			))}
		</Wrapper>
	);
}

export default Qna;

const Wrapper = styled.div`
	padding-top: 80px;
`;

const Title = styled.h2`
	color: #246cf6;
	font-size: 22px;
	line-height: 150%;
	white-space: pre-wrap;
	margin: 0 auto 24px 20px;
	font-weight: 700;
`;
