export const tanslateChFromToEnAddress = async (
  province: string = '',
  city: string = '',
  receipient: string = '',
  addressDetail: string = ''
) => {
  return new Promise((resolve, reject) => {
    const text = `${province}\n${city}\n${receipient}\n${addressDetail}`;

    const appKey = process.env.REACT_APP_YOUDAO_APP_KEY;
    const key = process.env.REACT_APP_YOUDAO_KEY;
    const salt = new Date().getTime();
    const curtime = Math.round(new Date().getTime() / 1000);
    const query = text;
    const from = 'zh-CHS';
    const to = 'en';
    const str1 = appKey + truncate(query) + salt + curtime + key;
    const sign = CryptoJS.SHA256(str1).toString(CryptoJS.enc.Hex);

    const payload = {
      q: query,
      appKey: appKey,
      salt: salt.toString(),
      from: from,
      to: to,
      sign: sign,
      signType: 'v3',
      curtime: curtime.toString(),
    };

    //@ts-ignore
    $.ajax({
      url: 'https://openapi.youdao.com/api',
      type: 'POST',
      dataType: 'jsonp',
      data: payload,
      success: function (data: { translation: string }) {
        const translateArray = data.translation[0].split('\n');
        const stateProvinceRegionEng = translateArray[0];
        const cityEng = translateArray[1];
        const addressEng = translateArray[2];
        const addressDetailEng = translateArray[3];

        resolve({
          stateProvinceRegionEng,
          cityEng,
          addressEng,
          addressDetailEng,
        });
      },
    });
  });
};

function truncate(q: any) {
  const len = q.length;
  if (len <= 20) return q;
  return q.substring(0, 10) + len + q.substring(len - 10);
}
