import { useHistory, useLocation } from 'react-router';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import storeIcon from 'assets/common/store.png';
import FlexWrap from 'components/_common/FlexWrap';
import Typography from 'components/_common/Typography';
import Logo from 'components/_common/Logo';
import { routes } from 'routes';
import { useAppDispatch } from 'hooks/useReduxHooks';
import { initialGlobalState } from 'store';

function Header() {
  const { pathname } = useLocation();
  const history = useHistory();
  const isLogin = localStorage.getItem('login');
  const dispatch = useAppDispatch();

  const isValidatePage = pathname === '/';

  const onClickLogout = () => {
    localStorage.removeItem('login');
    localStorage.removeItem('customerNation');
    dispatch({
      type: 'UPDATE_USER_INFO',
      payload: initialGlobalState,
    });
    history.push(routes.passport);
  };

  return (
    <MaxContainer>
      <HeaderContainer>
        <TranslationZone></TranslationZone>
        <Container>
          <Inner>
            <LogoWrapper>
              <Logo isTop={false} />
              {isValidatePage && (
                <FlexWrap alignItems='center' gap='8px'>
                  <Icon src={storeIcon} width='16px' height='16px' />
                  <Typography
                    size={isMobile ? '12px' : '15px'}
                    fontWeight='500'
                    lineHeight='20px'
                    color='#246CF6'
                  >
                    KIOSK CUSTOMER
                  </Typography>
                </FlexWrap>
              )}
            </LogoWrapper>
            {isLogin && !isValidatePage && (
              <Logout onClick={onClickLogout}>Sign out</Logout>
            )}
          </Inner>
        </Container>
      </HeaderContainer>
    </MaxContainer>
  );
}
const MaxContainer = styled.div`
  width: 375px;
  z-index: 60;
  position: fixed;
  top: 0;
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;
const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
`;
const TranslationZone = styled.div`
  height: 40px;
  background: #f1f1f1;
  @media ${(props) => props.theme.mobile} {
    height: 32px;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${(props) => props.theme.desktop} {
    width: 375px;
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;
const LogoWrapper = styled.div`
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media ${(props) => props.theme.mobile} {
  }
`;
const Icon = styled.img<{ width?: string; height?: string }>`
  width: ${(props) => (props.width ? props.width : '24px')};
  height: ${(props) => (props.height ? props.height : '24px')};
`;
const Logout = styled.button`
  width: 80px;
  color: #80848a;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  margin-right: 20px;
`;
export default Header;
