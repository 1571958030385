import React from 'react';
import styled from 'styled-components';

type StyleProps = {
	status?: 'active' | 'default' | 'opacity' | 'cancel';
	padding?: string;
	fontSize?: number;
	width?: string;
};

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
}

function FaqButton({
	children,
	status = 'default',
	...props
}: Props & StyleProps) {
	return (
		<ButtonWrapper {...props} status={status}>
			{children}
		</ButtonWrapper>
	);
}

export default FaqButton;

const ButtonWrapper = styled.button<StyleProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	border: ${({ theme, status }) =>
		status === 'opacity'
			? 'none'
			: status === 'cancel'
			? '1px solid #000'
			: `1px solid ${theme.ktpPrimary100}`};
	border-radius: 6px;
	width: ${({ width }) => width};
	padding: ${({ padding }) => (padding ? padding : '4px 16px')};
	font-size: ${({ fontSize }) => (fontSize ? fontSize : 14)}px;
	font-weight: 500;
	color: ${({ status, theme }) =>
		status === 'active'
			? '#fff'
			: status === 'opacity'
			? theme.ktpPrimary150
			: status === 'cancel'
			? '#000'
			: theme.ktpPrimary100};
	background-color: ${({ status, theme }) =>
		status === 'active'
			? theme.ktpPrimary100
			: status === 'opacity'
			? theme.ktpPrimary10
			: '#fff'};
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;
