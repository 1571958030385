import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from 'components/_common/ScrollToTop';
import LoginAuto from 'components/_common/LoginAuto';
import CustomModal from 'components/_common/CustomModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import CustomerRefund from 'pages/ktp/CustomerRefund';
import NotFound from 'pages/ktp/NotFound';
import VerifiactionRedirect from 'pages/ktp/VerificationRedirect';
import PassportValidate from 'pages/ktp/PassportValidate';
import RefundResult from 'pages/ktp/RefundResult';
import RefundInfo from 'pages/ktp/RefundInfo';
import { IGlobalState } from 'store';
import { Iso639_1Code } from 'utils/nations';
import RefundInfoLeaflet from 'pages/ktp/RefundInfoLeaflet';
import Faq from 'pages/ktp/Faq';
import PassportRedirect from 'pages/ktp/PassportRedirect';
import FaqAnswerModal from 'components/Faq/FaqAnswer.modal';
import CashPickupGuideModal from 'components/CustomerRefund/CashPickupGuide.modal';
import BankInquiryGuideModal from 'components/RefundResult/BankInquiryGuide.modal';
import Redirect from 'pages/ktp/Redirect/Redirect';

function RouterContainer() {
	const dispatch = useAppDispatch();
	const { nationality, globalModalInfo, hasNewAnswer } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const selectElement = document.querySelector('.goog-te-combo');

	function changeGoogleTranslateLanguage(nationality: string | null) {
		const nation = Iso639_1Code(nationality);
		if (!nation) return;
		//@ts-ignore
		selectElement.value = nation;
		//@ts-ignore
		selectElement.dispatchEvent(new Event('change'));
	}

	const onCloseModal = () => {
		dispatch({
			type: 'UPDATE_HOME_MODAL_STATE',
			payload: { isActive: false, data: null, type: null },
		});
	};

	useEffect(() => {
		selectElement && changeGoogleTranslateLanguage(nationality);
	}, [selectElement, nationality]);

	return (
		<Container>
			<Translation id='google_translate_element'></Translation>
			<Router>
				<Redirect />
				<LoginAuto />
				<ScrollToTop />
				<Switch>
					<Route path='/' component={PassportValidate} exact />
					<Route
						path='/redirect/:passportNumber'
						component={PassportRedirect}
					/>
					<Route
						path='/email-verification/:id/:verifiedEmail'
						component={VerifiactionRedirect}
					/>
					<Route path='/refund-result' component={RefundResult} />
					<Route path='/refund' component={CustomerRefund} />
					<Route path='/refund-info-leaflet' component={RefundInfoLeaflet} />
					<Route path='/refund-info' component={RefundInfo} />
					<Route path='/faq' component={Faq} />
					<Route>
						<NotFound />
					</Route>
				</Switch>
				{hasNewAnswer && <FaqAnswerModal />}
			</Router>
			{/* 커스텀 모달 */}
			{globalModalInfo?.type === 'CUSTOM' && globalModalInfo?.isActive && (
				<CustomModal onClose={onCloseModal} {...globalModalInfo?.data} />
			)}
			{nationality === 'TWN' &&
				globalModalInfo?.type === 'CASH_PICKUP_GUIDE' && (
					<CashPickupGuideModal onClose={onCloseModal} />
				)}
			{nationality === 'CHN' &&
				globalModalInfo?.type === 'BANK_INQUIRY_GUIDE' && (
					<BankInquiryGuideModal
						title={
							<>
								<InfoTitle>因为海外汇款，银行可能会来咨询。</InfoTitle>
								<InfoTitle>那种时候请这样回答。</InfoTitle>
							</>
						}
						questionTitle='银行'
						questionText='与Successmode是什么关系?'
						answerTitle='您'
						answerText='Successmode作为退税窗口事业者代理Tax Refund。(代表人名 "Ju Byeong-Cheon" 可汇款。)'
						btnTitle='确认'
					/>
				)}
			{nationality === 'JPN' &&
				globalModalInfo?.type === 'BANK_INQUIRY_GUIDE' && (
					<BankInquiryGuideModal
						title={
							<>
								<InfoTitle>
									海外送金のために銀行から問い合わせが来ることもあります。
								</InfoTitle>
								<InfoTitle>そういう時はこう答えてください。</InfoTitle>
							</>
						}
						questionTitle='ぎんこう'
						questionText='Successmodeとはどのような関係ですか?'
						answerTitle='あなた'
						answerText="Successmodeは還付窓口運営事業者として 「TAX REFUND」を代行します。 (代表者 'Ju Byeong-Cheon' に入金されることがあります。)"
						btnTitle='確認'
					/>
				)}
		</Container>
	);
}

export default RouterContainer;

const Container = styled.div`
	position: static;
`;

const Translation = styled.div`
	position: fixed;
	z-index: 200;
	@media ${(props) => props.theme.desktop} {
		right: 42%;
		top: 2px;
	}
	@media ${(props) => props.theme.mobile} {
		top: 0px;
		right: 20px;
	}
`;

const InfoTitle = styled.span`
	display: block;
	font-weight: 600;
	line-height: 150%;
`;
