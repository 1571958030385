import { legacy_createStore as createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

export interface IGlobalState {
	passportNumber: null | string;
	nationality: string;
	register: boolean;
	email: string;
	paymentStatus: 'PAYMENT_WAIT' | 'PAYMENT_COMPLETE';
	refundAmount: string;
	verifiedEmail: string | null;
	globalModalInfo: {
		type: 'CUSTOM' | 'CASH_PICKUP_GUIDE' | 'BANK_INQUIRY_GUIDE' | null;
		isActive: boolean;
		data: any;
	};
	hasNewAnswer: boolean;
}
export const initialGlobalState = {
	passportNumber: null,
	email: null,
	nationality: null,
	register: false,
	paymentStatus: null,
	refundAmount: 0,
	verifiedEmail: null,
	globalModalInfo: {
		type: null,
		isActive: false,
		data: null,
	},
	hasNewAnswer: false,
};
const persistConfig = {
	key: 'root',
	storage: storageSession,
};
function reducer(state = initialGlobalState, action: any) {
	switch (action.type) {
		case 'UPDATE_USER_INFO':
			return { ...state, ...action.payload };
		case 'UPDATE_HOME_MODAL_STATE':
			return {
				...state,
				globalModalInfo: action.payload,
			};
		default:
			return state;
	}
}
const perReducer = persistReducer(persistConfig, reducer);
export const store = createStore(perReducer);
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
