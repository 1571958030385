import { Spin } from 'animation';
import styled from 'styled-components';
import FlexWrap from './FlexWrap';

type Props = {
  description?: string;
};
function LoadingView({ description }: Props) {
  return (
    <Container>
      <FlexWrap dir='column' alignItems='center'>
        {description && <Text>{description}</Text>}
        <LoadingSpinner />
      </FlexWrap>
    </Container>
  );
}
const Container = styled.div`
  width: 335px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingSpinner = styled.div`
  animation: ${Spin} 600ms linear infinite;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 6px solid #e9f0fe;
  border-top: 6px solid #246cf6;
`;
const Text = styled.p`
  margin-bottom: 20px;
  color: #246cf6;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
  line-height: 150%;
`;
export default LoadingView;
