import { JPN_CITY_LIST } from 'constant/jpnCityList.const';
import React, { useEffect, useState } from 'react';

const useFindKeyword = () => {
	const [keyword, setKeyword] = useState('');
	const [searchList, setSearchList] = useState<string[]>([]);

	const findCity = () => {
		let list: string[] = [];
		JPN_CITY_LIST.map((city) => {
			if (city.toUpperCase().includes(keyword.toUpperCase())) {
				list.push(city);
			}
		});
		return list;
	};

	useEffect(() => {
		const cityList = findCity();
		setSearchList(cityList);
	}, [keyword]);

	return { searchList, keyword, setKeyword };
};

export default useFindKeyword;
