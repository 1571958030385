import React from 'react';
import styled from 'styled-components';

type Props = {
	title: React.ReactNode;
	questionTitle: string;
	questionText: string;
	answerTitle: string;
	answerText: string;
};

function BankInquiryGuide({
	title,
	questionTitle,
	questionText,
	answerTitle,
	answerText,
}: Props) {
	return (
		<Wrapper>
			<p>{title}</p>
			<QuestionWrapper isQuestion>
				<QuestionTitle isQuestion>{questionTitle}</QuestionTitle>
				<QuestionText isQuestion>{questionText}</QuestionText>
			</QuestionWrapper>
			<QuestionWrapper>
				<QuestionTitle>{answerTitle}</QuestionTitle>
				<QuestionText>{answerText}</QuestionText>
			</QuestionWrapper>
		</Wrapper>
	);
}

export default BankInquiryGuide;

type StyleProps = {
	isQuestion?: boolean;
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 50px;
`;

const QuestionWrapper = styled.dl<StyleProps>`
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px;
	border-radius: 12px;
	background-color: ${({ isQuestion }) => (isQuestion ? '#f5f6f7' : '#F4F8FF')};
`;

const QuestionTitle = styled.dt<StyleProps>`
	border-radius: 12px;
	flex: 1;
	min-width: 36px;
	padding: 4px;
	color: ${({ isQuestion }) => (isQuestion ? '#80848a' : '#fff')};
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
	word-break: keep-all;
	background-color: ${({ isQuestion }) => (isQuestion ? '#e5e6e8' : '#246CF6')};
`;

const QuestionText = styled.dd<StyleProps>`
	flex: 4;
	text-align: left;
	border-radius: 12px;
	padding: 4px;
	color: ${({ isQuestion }) => (isQuestion ? '#3a3b3e' : '#246CF6')};
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
`;
