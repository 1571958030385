import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { useAppDispatch } from 'hooks/useReduxHooks';
import useBlockAccessIfNotLoggedIn from 'hooks/useBlockAccessIfNotLoggedIn';

function LoginAuto() {
  const loginToken = localStorage.getItem('login');
  const dispatch = useAppDispatch();
  // useBlockAccessIfNotLoggedIn();

  useEffect(() => {
    if (isMobile) {
      if (loginToken) {
        dispatch({
          type: 'UPDATE_USER_INFO',
          payload: {
            passportNumber: loginToken,
          },
        });
      }
    }
  }, []);

  return <></>;
}

export default LoginAuto;
