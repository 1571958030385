export const Iso639_1Code = (nationCode: string | null): string | undefined => {
  //'KOR', 'USA', 'GBR', 'AUS' 제외(구글 번역 안시키고 화면단에서 영어 버전, 한국어 버전 보여주기 위함)
  switch (nationCode) {
    case 'CHN':
      return 'zh-CN';
    case 'THA':
      return 'th';
    case 'JPN':
      return 'ja';
    case 'ESP':
      return 'es';
    case 'FRA':
      return 'fr';
    case 'D':
    case 'DEU':
      return 'de';
    case 'ITA':
      return 'it';
    case 'NLD':
      return 'nl';
    case 'SWE':
      return 'sv';
    case 'IND':
      return 'id';
    case 'NOR':
      return 'no';
    case 'RUS':
      return 'ru';
    case 'VNM':
      return 'vi';
    case 'POL':
      return 'pl';
    case 'BGR':
      return 'bg';
    default:
      return undefined;
  }
};
