import axios from 'axios';
import { axiosInstance, axiosRequestConfiguration } from './config';
import {
	IRegisterRefundInfoPayload,
	IVerifyPassportPayload,
	IVerifyPassportResponse,
	ISendVerificationMailPayload,
	IVerifyEmailPayload,
	IRegisterRefundInfoResponse,
	IMyQna,
	ISendInquiryPayload,
} from 'model/types';
import { ISendInquiryMailPayload } from 'types/ktp';

// const axiosInstance = axios.create(axiosRequestConfiguration);

const getAxios = async <ResponseType, ParamType>(
	url: string,
	queryParams?: ParamType,
	config?: any
) =>
	axiosInstance.get<ResponseType>(url, {
		...(queryParams && { params: queryParams }),
		...config,
	});
const postAxios = async <ReturnType, BodyType, ParamType>(
	url: string,
	body: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.post<ReturnType>(url, body, {
		...(queryParams && { params: queryParams }),
	});
const patchAxios = async <ResponseType, BodyType, ParamType>(
	url: string,
	body?: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.patch<ResponseType>(url, body, {
		...(queryParams && { params: queryParams }),
	});
const putAxios = async <ResponseType, BodyType, ParamType>(
	url: string,
	body?: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.put<ResponseType>(url, body, {
		...(queryParams && { params: queryParams }),
	});

const deleteAxios = async <ResponseType, BodyType, ParamType>(
	url: string,
	body?: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.delete<ResponseType>(url, {
		...(body && { data: body }),
		...(queryParams && { params: queryParams }),
	});

//================== KTP ================================================
const ERP_SERVER = 'https://erp.successmode.co.kr';
//여권 인증
export const verifyPassport = async (payload: IVerifyPassportPayload) => {
	const res = await postAxios<
		IVerifyPassportResponse,
		IVerifyPassportPayload,
		null
	>(`/refund/receipt/kiosk/passport-validate`, payload);
	return res.data;
};
//송금 정보 입력
export const registerRefundInfo = async ({
	isForTemporaryStorage,
	...payload
}: IRegisterRefundInfoPayload) => {
	await postAxios<
		IRegisterRefundInfoResponse,
		IRegisterRefundInfoPayload,
		null
	>(`/refund/receipt/account/`, payload);
	return { isForTemporaryStorage: isForTemporaryStorage };
};
//검증 메일 전송
export const sendVerificationMail = async (
	payload: ISendVerificationMailPayload
) => {
	const res = await postAxios<number, ISendVerificationMailPayload, null>(
		`/mail/refund/receipt/ktour`,
		payload
	);
	return res.status;
};
//이메일 검증
export const verifyEmail = async (payload: IVerifyEmailPayload) => {
	const res = await patchAxios<number, IVerifyEmailPayload, null>(
		`/refund/receipt/mail`,
		payload
	);
	return res.status;
};

//송금 정보 조회
export const getRefundInfo = async (passportNumber: string | null) => {
	const res = await getAxios<any, string>(
		`/refund/receipt/account/${passportNumber}`
	);
	return res.data;
};
//이메일 인증여부 확인
export const checkEmailAuth = async (payload: any) => {
	const res = await postAxios<any, any, null>(
		`/refund/receipt/mail-check`,
		payload
	);
	return res.data;
};
// 리플렛 고객 송금 정보 입력
export const getRefundInfoData = async (payload: FormData) => {
	const res = await postAxios<string, FormData, null>(
		`${ERP_SERVER}/customer/fault/leaflet`,
		payload
	);
	return res.data;
};
// 나의 문의 내역 조회
export const getMyQnaList = async (passportNumber: string) => {
	const res = await getAxios(`/question/${passportNumber}`);
	return res.data as IMyQna[];
};
// 문의하기
export const sendInquiry = async (payload: ISendInquiryPayload) => {
	const res = await postAxios(`/question`, payload);
	return res.status;
};
// 문의 답변 읽음처리
export const updateNewAnswer = async (passportNumber: string) => {
	const res = await patchAxios(`/question/${passportNumber}`);
	return res.data;
};

//문의 메일 전송
export const sendInquiryMail = async (payload: ISendInquiryMailPayload) => {
	const res = await postAxios<number, ISendInquiryMailPayload, null>(
		`/mail/inquiries`,
		payload
	);
	return res.status;
};
