import React from 'react';
import styled from 'styled-components';

import Coins from 'assets/common/coins.png';
import FlexWrap from 'components/_common/FlexWrap';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import { comma } from 'utils/format';

function RefundAmount() {
  const { refundAmount } = useAppSelector((state) => state) as IGlobalState;

  return (
    <Container>
      <RefundIcon src={Coins} />
      <FlexWrap dir='column' alignItems='flex-start'>
        <FlexWrap gap='4px'>
          <RefundLabel>My Refunds</RefundLabel>
        </FlexWrap>
        <FlexWrap alignItems='center' gap='8px'>
          <Currency>KRW</Currency>
          <Amount className='notranslate'>{comma(refundAmount)}</Amount>
        </FlexWrap>
      </FlexWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f6f7;
  padding: 16px;
  border-radius: 10px;
  gap: 20px;
  width: 100%;
  margin-bottom: 24px;
`;
const RefundIcon = styled.img`
  width: 68px;
  height: 68px;
`;
const Text = styled.span`
  line-height: 150%;
  color: #030303;
`;
const RefundLabel = styled(Text)`
  font-size: 14px;
`;
const BoldText = styled(Text)`
  font-weight: 700;
`;
const Currency = styled(BoldText)`
  font-size: 18px;
`;
const Amount = styled(BoldText)`
  font-size: 24px;
`;
export default RefundAmount;
