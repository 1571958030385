import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { checkEmailAuth } from 'api';

const useCheckEmailAuth = (email: string, nationality: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError, any>(
    (payload) => checkEmailAuth(payload),
    {
      retry: false,
      onSuccess: (res) => {
        if (res) {
          dispatch({
            type: 'UPDATE_USER_INFO',
            payload: {
              verifiedEmail: email,
            },
          });
        } else {
          alert(
            nationality === 'KOR'
              ? '이메일 인증이 되지 않았습니다.\n전송된 인증 메일을 확인해주세요. '
              : nationality === 'CHN'
              ? '电子邮件验证失败。\n请检查您收到的验证电子邮件。'
              : nationality === 'JPN'
              ? 'メール認証に失敗しました。\n受信した認証メールを確認してください。'
              : 'Email verification failed.\nPlease check the verification email you received. '
          );
        }
      },
    }
  );
};

export default useCheckEmailAuth;
