import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import InnerLayout from "components/_common/InnerLayout";
import { routes } from "routes";

const Inner = styled.section`
  padding: 215px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 750px);
  @media ${(props) => props.theme.mobile} {
    margin: 100px 0;
  }
`;

const Title = styled.h2`
  text-align: center;
  letter-spacing: -0.7px;
  & span {
    color: ${(props) => props.theme.primaryColor};
  }
  @media ${(props) => props.theme.desktop} {
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
    margin: 0 0 30px;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 0 15px;
  }
`;

const Content = styled.p`
  color: ${(props) => props.theme.fontColorContent};
  text-align: center;
  word-break: keep-all;
  letter-spacing: -0.7px;
  @media ${(props) => props.theme.desktop} {
    max-width: 460px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 50px;
  }
  @media ${(props) => props.theme.mobile} {
    max-width: 340px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 40px;
  }
`;

const ButtonWrapper = styled.div``;

const Button = styled.button`
  outline: none;
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
  border: 1px solid ${(props) => props.theme.fontColorFooter};
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  &:nth-of-type(1) {
    margin-right: 10px;
  }
  @media ${(props) => props.theme.desktop} {
    width: 160px;
  }
  @media ${(props) => props.theme.mobile} {
    width: 120px;
  }
`;

function NotFound() {
  const history = useHistory();

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onGoHome = useCallback(() => {
    history.push(routes.passport);
  }, [history]);

  return (
    <InnerLayout>
      <Inner>
        <Title>
          페이지를 <span>찾을 수 없습니다.</span> <br />
          (404 Not Found)
        </Title>
        <Content>
          페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다. 입력하신 주소가
          정확한지 다시 한번 확인해 주시기 바랍니다.
        </Content>
        <ButtonWrapper>
          <Button onClick={onBack}>이전 화면</Button>
          <Button onClick={onGoHome}>홈으로 가기</Button>
        </ButtonWrapper>
      </Inner>
    </InnerLayout>
  );
}

export default NotFound;
