import styled from 'styled-components';
import ICON_CHECK from 'assets/cashPickup/icon_check_circle_line.png';
import ICON_CHECK_FILL from 'assets/cashPickup/icon_check_circle_line_fill.png';
import { useAppDispatch } from 'hooks/useReduxHooks';

type Props = {
	isReadPickupGuide: boolean;
	setIsReadPickupGuide: React.Dispatch<React.SetStateAction<boolean>>;
};

function CashPickupGuideCheckbox({
	isReadPickupGuide,
	setIsReadPickupGuide,
}: Props) {
	const dispatch = useAppDispatch();

	const openModal = () => {
		setIsReadPickupGuide(true);
		dispatch({
			type: 'UPDATE_HOME_MODAL_STATE',
			payload: {
				type: 'CASH_PICKUP_GUIDE',
				isActive: true,
			},
		});
	};

	return (
		<Wrapper type="button" onClick={openModal} isRead={isReadPickupGuide}>
			{isReadPickupGuide ? (
				<img src={ICON_CHECK_FILL} alt="" width={24} height={24} />
			) : (
				<img src={ICON_CHECK} alt="" width={24} height={24} />
			)}
			Check the ‘Guide for Cash Pick-up’
		</Wrapper>
	);
}

export default CashPickupGuideCheckbox;

type StyleProps = {
	isRead: boolean;
};

const Wrapper = styled.button<StyleProps>`
	display: flex;
	padding: 14px;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	border: 1px solid ${({ isRead }) => (isRead ? '#246CF6' : '#cbccce')};
	width: 100%;
	color: ${({ isRead }) => (isRead ? '#246CF6' : '#cbccce')};
	font-size: 14px;
	line-height: 150%; /* 21px */
`;
