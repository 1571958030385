import React from 'react';
import styled from 'styled-components';

type ProgressBarType = {
  percent: number;
};
function ProgressBar({ percent }: ProgressBarType) {
  return (
    <Container>
      <Bar percent={percent} />
    </Container>
  );
}
const Container = styled.div`
  position: fixed;
  top: 91px;
  width: 100%;
  height: 4px;
  background-color: #e5e6e8;
  width: 375px;
  z-index: 10;
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    top: 83px;
  }
`;
const Bar = styled.div<{ percent: number }>`
  width: ${(props) => props.percent}%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(167, 196, 251, 1) 0%,
    rgba(36, 108, 246, 1) 100%
  );
`;
export default ProgressBar;
