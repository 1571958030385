import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { IGlobalState, initialGlobalState } from 'store';
import styled from 'styled-components';
import { debounce } from 'lodash-es';

import { verifyPassport } from 'api';
import PassportInputForm from 'components/PassportValidate/PassportInputForm';
import ReceiptLayout from 'components/_common/ReceiptLayout';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IVerifyPassportPayload, IVerifyPassportResponse } from 'model/types';

export interface IErrorState {
	message: string;
}
function PassportValidate() {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const {
		passportNumber: currPassportNumber,
		email,
		register,
	} = useAppSelector((state) => state) as IGlobalState;

	const formMethod = useForm({
		defaultValues: {
			passportNumber: '',
			nationality: '',
		},
	});

	const { passportNumber } = formMethod.watch();
	const [error, setError] = useState<IErrorState | undefined>();

	const passportMutation = useMutation<
		IVerifyPassportResponse,
		AxiosError,
		IVerifyPassportPayload
	>((payload) => verifyPassport(payload), {
		retry: false,
		onSuccess: ({ register, nation, refundAmount, email, paymentStatus }) => {
			if (+refundAmount === 0) {
				setError({
					message: 'Please verify your Passport Number and try again.',
				});
				return;
			}
			nation && localStorage.setItem('customerNation', nation);
			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					passportNumber: passportNumber.toUpperCase(),
					register,
					nationality: nation,
					refundAmount,
					email,
					paymentStatus,
				},
			});
			setError(undefined);
			localStorage.setItem('login', passportNumber);
			history.push(register ? routes.refundInfo : routes.customerRefund);
		},
		onError: ({ data }: any) => {
			setError({
				message: 'Please verify your Passport Number and try again.',
			});
			if (data.code === 'P0001') {
				dispatch({
					type: 'UPDATE_HOME_MODAL_STATE',
					payload: {
						isActive: true,
						type: 'CUSTOM',
						data: {
							title: 'Unable to Verify passport.',
							content: `${passportNumber.toUpperCase()}\n\nDid you enter passport number correctly?\nIf yes, press [Confirm] to continue,`,
							subBtnText: 'Cancel',
							btnText: 'Confirm',
							btnCallback: onClickCofirm,
						},
					},
				});
			}
		},
	});

	// 여러번 여권조회 중복호출 방지
	const debouncedMutation = useCallback(
		debounce((formattedPassportNumber) => {
			passportMutation.mutate({ passportNumber: formattedPassportNumber });
		}, 500),
		[passportMutation]
	);

	const onSubmitPassportNumber = () => {
		const formattedPassportNumber = passportNumber
			.toUpperCase()
			.replace(/\s+/g, '');
		debouncedMutation(formattedPassportNumber);
	};

	const onClickCofirm = () => {
		//TODO: 정보 입력 페이지로 이동해 자사로 메일 전송하는 기능 구현
		history.push(routes.refundInfoLeaflet, { passportNumber });
	};

	useEffect(() => {
		const handleBackButton = () => {
			if (currPassportNumber) {
				dispatch({
					type: 'UPDATE_USER_INFO',
					payload: initialGlobalState,
				});
				localStorage.removeItem('login');
				localStorage.removeItem('customerNation');
			}
			window.history.go(1);
		};
		window.addEventListener('popstate', handleBackButton);
		return () => window.removeEventListener('popstate', handleBackButton);
	}, []);

	return (
		<ReceiptLayout>
			<Wrapper>
				<FormProvider {...formMethod}>
					<PassportInputForm
						onSubmit={onSubmitPassportNumber}
						{...(error && { error })}
					/>
				</FormProvider>
			</Wrapper>
		</ReceiptLayout>
	);
}

export default PassportValidate;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px 0 100px;
`;
