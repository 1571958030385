import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import FaqButton from 'components/_common/FaqButton';
import Icon from 'components/_common/Icon';
import csIcon from 'assets/common/icon_cs.png';
import { useAppSelector } from 'hooks/useReduxHooks';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import Typography from 'components/_common/Typography';

function TopSection() {
	const { nationality, passportNumber } = useAppSelector((state) => state);
	const history = useHistory();

	const goToQnA = () => {
		history.push(routes.faq);
	};

	return (
		<Container dir="column" justifyContent="center">
			<FlexWrap
				justifyContent="space-between"
				alignItems="center"
				margin="30px 0 0 0"
			>
				<Typography
					size="21px"
					fontWeight="500"
					color="#1856cd"
				>{`${passportNumber}!\nPlease verify you\nemail`}</Typography>
				<FaqButton onClick={goToQnA}>
					<Icon imgUrl={csIcon} width="20px" height="20px" margin="0 4px 0 0" />
					{nationality === 'KOR' ? '문의하기' : 'Inquiry'}
				</FaqButton>
			</FlexWrap>
		</Container>
	);
}

const Container = styled(FlexWrap)`
	padding: 0 20px 20px;
	width: 375px;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
	}
`;

export default TopSection;
