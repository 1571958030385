import { useEffect } from 'react';
import styled from 'styled-components';
import ReceiptLayout from 'components/_common/ReceiptLayout';
import TextButton from 'components/_common/TextButton';
import ProgressBar from 'components/_common/ProgressBar';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import RefundAmount from 'components/_common/RefundAmount';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { getIsFrequentCountry } from 'utils/common';
import BankInquiryGuide from 'components/RefundResult/BankInquiryGuide';

function RefundResult() {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { nationality, passportNumber } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const onClick = () => {
		history.push(routes.refundInfo);
	};

	useEffect(() => {
		dispatch({
			type: 'UPDATE_USER_INFO',
			payload: { register: true },
		});
	}, []);

	useEffect(() => {
		const handleBackButton = () => {
			window.history.go(1);
		};
		window.addEventListener('popstate', handleBackButton);
		return () => {
			window.removeEventListener('popstate', handleBackButton);
		};
	}, []);

	return (
		<ReceiptLayout hasPadding={false}>
			<ProgressBar percent={100} />
			<Title>{`${passportNumber}!\nInformation entered completely.`}</Title>
			<Wrapper>
				<RefundAmount />
				<Description>
					{getIsFrequentCountry(nationality)
						? 'Please show your purchases and your\nMobile tax refund receipt to customs.\nThen we’ll transfer your refund amount\nto the information you provided within 21 business days.'
						: "Please show your purchases and your\nMobile tax refund receipt to customs.\nThen we'll send an email to request your information\nfor processing refunds within 10 days.\nPlease check your mailbox."}
					{nationality === 'JPN' && (
						<BankInquiryGuide
							title={
								<>
									<InfoTitle>
										海外送金のために銀行から問い合わせが来ることもあります。
									</InfoTitle>
									<InfoTitle>そういう時はこう答えてください。</InfoTitle>
								</>
							}
							questionTitle="ぎんこう"
							questionText="Successmodeとはどのような関係ですか?"
							answerTitle="あなた"
							answerText='Successmodeは還付窓口運営事業者として 「TAX REFUND」を代行します。 (代表者 "Ju Byeong-Cheon" に入金されることがあります。)'
						/>
					)}
					{nationality === 'CHN' && (
						<BankInquiryGuide
							title={
								<>
									<InfoTitle>因为海外汇款，银行可能会来咨询。</InfoTitle>
									<InfoTitle>那种时候请这样回答。</InfoTitle>
								</>
							}
							questionTitle="银行"
							questionText="与Successmode是什么关系?"
							answerTitle="您"
							answerText='Successmode作为退税窗口事业者代理 Tax Refund。(代表人名"JuByeong-Cheon")'
						/>
					)}
				</Description>
			</Wrapper>
			<TextButton
				title="Confirm"
				bgColor="#246CF6"
				color="#FFFFFF"
				outline
				margin="100px 0 0"
				onClick={onClick}
			/>
		</ReceiptLayout>
	);
}

export default RefundResult;

const Title = styled.h2`
	font-size: 21px;
	color: #1856cd;
	padding-left: 20px;
	margin: 30px 0 20px;
	white-space: pre-wrap;
	line-height: 150%;
	text-align: left;
	font-weight: 500;
	width: 100%;
`;

const Wrapper = styled.div`
	width: 335px;
	@media ${(props) => props.theme.mobile} {
		padding: 0 20px;
		width: 100%;
	}
`;

const Description = styled.p`
	font-size: 14px;
	color: #3a3b3e;
	line-height: 150%;
	white-space: pre-wrap;
	text-align: center;
	margin-bottom: 100px;
	@media ${(props) => props.theme.mobile} {
		margin-bottom: 70px;
	}
`;

const InfoTitle = styled.span`
	display: block;
	color: #246cf6;
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
`;
