import React from 'react';
import styled, { css } from 'styled-components';
import ArrowRight from 'assets/common/arrow_right_white.png';

interface IProps {
  onClick?: any;
  title: string;
  bgColor?: string;
  color?: string;
  disabled?: boolean;
  margin?: string;
  outline?: boolean;
  isActive?: boolean;
  width?: string;
  bottomFixed?: boolean;
  fontSize?: string;
  hasArrow?: boolean;
  icon?: string;
  style?: any;
}
function TextButton({
  title,
  isActive = true,
  bottomFixed,
  hasArrow,
  icon,
  ...btnProps
}: IProps) {
  return (
    <>
      {bottomFixed ? (
        <BtnWrapper>
          <StyledBtn isActive={isActive} {...btnProps}>
            {' '}
            {icon && <Icon src={icon} />}
            {title}
            {hasArrow && <Icon src={ArrowRight} />}
          </StyledBtn>
        </BtnWrapper>
      ) : (
        <StyledBtn isActive={isActive} {...btnProps}>
          {' '}
          {icon && <Icon src={icon} />}
          {title}
          {hasArrow && <Icon src={ArrowRight} />}
        </StyledBtn>
      )}
    </>
  );
}
const StyledBtn = styled.button<{
  bgColor?: string;
  color?: string;
  margin?: string;
  outline?: boolean;
  isActive?: boolean;
  width?: string;
  bottomFixed?: boolean;
  fontSize?: string;
}>`
  height: 52px;
  line-height: 52px;
  background-color: ${(props) =>
    props.isActive ? props.bgColor || '#246CF6' : '#D3E2FD'};
  font-size: ${(props) => props.fontSize || '18px'};
  color: ${(props) => props.color || '#FFFFFF'};
  border-radius: 8px;
  margin: ${(props) => props.margin || 0};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  :hover {
    opacity: 0.8;
  }
  :disabled {
    cursor: default;
  }
  ${(props) =>
    props.outline &&
    css`
      border: 1.5px solid ${props.color};
    `}
  @media ${(props) => props.theme.mobile} {
    width: ${(props) => props.width || '100%'};
  }
  @media ${(props) => props.theme.desktop} {
    width: ${(props) => props.width || '335px'};
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  left: 0;
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
export default TextButton;
