export const bankList = [
	'없음',
	'국민은행',
	'기업은행',
	'농협은행',
	'신한은행',
	'산업은행',
	'우리은행',
	'하나씨티은행',
	'SC제일은행',
	'경남은행',
	'광주은행',
	'대구은행',
	'도이치은행',
	'뱅크오브아메리카',
	'부산은행',
	'산림조합은행',
	'저축은행',
	'새마을은행',
	'수협은행',
	'신협은행',
	'우체국은행',
	'전북은행',
	'제주은행',
	'중국건설은행',
	'중국공상은행',
	'중국은행',
	'BNP은행',
	'HSBC은헹',
	'JP모건은행',
	'케이뱅크',
	'토스뱅크',
	'카카오뱅크',
	'교보증권',
	'대신증권',
	'DB증권',
	'메리츠증권',
	'미래에셋증권',
	'부국증권',
	'삼성증권',
	'신명증권',
	'신한증권',
	'SK증권',
	'현대증권',
	'유안타증권',
	'이베스트증권',
	'케이프증권',
	'키움증권',
	'한국포스증권',
	'하나증권',
	'하이투자증권',
	'한국증권',
	'한화증권',
	'KB증권',
	'다올증권',
	'BNK증권',
	'NH증권',
	'카카오페이증권',
	'IBK증권',
	'토스증권',
];
export const qnaData = [
	{
		title: '환급액은 언제 받을 수 있나요?',
		content:
			'환급액은 출국 확인 후 송금 완료까지 영업일 기준 21일 정도\n소요됩니다.',
	},
	{
		title: '환급 절차가 어떻게 되나요?',
		content: `송금 정보 입력 → 세관 방문 → 송금\n\n앞선 절차를 진행해주셔야 환급까지 이루어질 수 있습니다.\n\n[공항 와이파이 접속 후 반출 미승인이 될 경우]\n세관 확인 대상이므로 세관 확인이 필요합니다.`,
	},
	{
		title: '절차를 완수했는데도 환급을 못 받았습니다.',
		content: `환급액은 반출 승인 후 송금 완료까지 영업일 기준 21일 정도\n소요됩니다.\n환급정보가 잘 못 기입 되었거나 반출 승인을 받지 못하셨다면\n환급이 불가합니다. 반출 승인을 받으셨다면 환급 정보를\n다시 한 번 확인 해주세요.`,
	},
	{
		title: '세관 확인을 못 받고 출국했습니다. 환급 받을 수 있나요?',
		content: '반출 승인을 받지 못하셨으면 환급이 불가합니다.',
	},
];
export const qnaDataEng = [
	{
		title: 'When will I receive my refunds?',
		content:
			'Tax refunds take an average of 21 business days.\nHowever, please understand that there may be a little\nmore delay depending on various circumstances.',
	},
	{
		title: 'How does the tax refund process work?',
		content: `Enter Information → Visit Customs → Receive Refunds\n\nEnter your information on the KTP website and show your purchases and mobile receipt to customs. After this process, we will transfer the refund amount.`,
	},
	{
		title: `I completed the process but still didn't receive the refund.`,
		content: `It takes about 21 business days for the transfer. If the\ninformation was filled out incorrectly or you didn't go to\ncustoms, then we can't refund you. If you followed the\nprocedure and didn't get your money, please double-\ncheck your information.`,
	},
	{
		title: 'I left the country without going to customs, can I get a refund?',
		content: `Unfortunately, if you didn't go to customs, you can't get a refund.`,
	},
];
