import React from 'react';
import styled from 'styled-components';

type TypographyProps = {
  size?: string;
  lineHeight?: string;
  fontWeight?: string;
  color?: string;
  margin?: string;
  cursor?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

function Typography({
  size,
  lineHeight,
  fontWeight,
  color,
  margin,
  cursor,
  children,
  onClick = () => {},
}: TypographyProps) {
  return (
    <Cotainer margin={margin} onClick={onClick}>
      <Text
        size={size}
        lineHeight={lineHeight}
        fontWeight={fontWeight}
        color={color}
        cursor={cursor}
      >
        {children}
      </Text>
    </Cotainer>
  );
}
const Cotainer = styled.div<{ margin?: string }>`
  display: inline-block;
  margin: ${(props) => props.margin};
  white-space: pre-wrap;
`;
const Text = styled.span<{
  size?: string;
  lineHeight?: string;
  fontWeight?: string;
  color?: string;
  margin?: string;
  cursor?: string;
}>`
  font-size: ${(props) => props.size};
  line-height: ${(props) => props.lineHeight || '150%'};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  cursor: ${(props) => props.cursor};
`;

export default Typography;
