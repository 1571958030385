import { getRefundInfo, registerRefundInfo } from 'api';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import styled from 'styled-components';

import EmailVerification from 'components/CustomerRefund/EmailVerification';
import TopSection from 'components/CustomerRefund/TopSection';
import BankInfoForm from 'components/Receipt/BankInfoForm';
import RefundInfoForm from 'components/Receipt/RefundInfoForm';
import CheckboxWithLabel from 'components/_common/CheckboxWithLabel';
import LoadingView from 'components/_common/LoadingView';
import ReceiptLayout from 'components/_common/ReceiptLayout';
import RefundAmount from 'components/_common/RefundAmount';
import TextButton from 'components/_common/TextButton';
import useCheckEmailAuth from 'hooks/useCheckEmailAuth';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IRefundInfoForm, IRegisterRefundInfoResponse } from 'model/types';
import { IGlobalState } from 'store';
import { getIsFrequentCountry } from 'utils/common';
import { tanslateChFromToEnAddress } from 'utils/translate';
import { IAddressTranslation } from 'types/ktp';
import FaqButton from 'components/Faq/FaqButton';
import ICON_CS from 'assets/common/icon_cs.png';
import CashPickupGuideButton from 'components/CustomerRefund/CashPickupGuideButton';
import CashPickupGuideCheckbox from 'components/CustomerRefund/CashPickupGuideCheckbox';

function CustomerRefund() {
	const { passportNumber, nationality, verifiedEmail, email } = useAppSelector(
		(state) => state
	) as IGlobalState;

	const [isFirstTermSelected, setIsFirstTermSelected] = useState(true);
	const [isSecondTermSelected, setIsSecondTermSelected] = useState(true);
	const [selectedGender, setSelectedGender] = useState('');
	const [selectedTransfer, setSelectedTransfer] = useState('');
	const [selectedAccountInfo, setSelectedAccountInfo] = useState('');
	const [selectedAccountType, setSelectedAccountType] = useState('');
	const [selectedBank, setSelectedBank] = useState('');
	const [selectedRegion, setSelectedRegion] = useState('');
	const [selectedTown, setSelectedTown] = useState('');
	const [isBtnActive, setIsBtnActive] = useState(false);
	const [isBankCardInquiry, setIsBankCardInquiry] = useState(false);
	const [isHkgNation, setIsHkgNation] = useState(false);
	const [isVerified, setIsVerified] = useState(
		!verifiedEmail ? false : verifiedEmail === email
	);

	const { mutateAsync: checkEmailAuthMutate } = useCheckEmailAuth(
		email,
		nationality
	);

	const isFrequentCountry = getIsFrequentCountry(nationality);
	const [currentStep, setCurrentStep] = useState(1); //현재 단계
	const targetStep = isFrequentCountry ? 2 : 1; //마지막 단계
	const history = useHistory();
	const {
		handleSubmit,
		register,
		getValues,
		watch,
		formState: { errors },
		setValue,
		reset,
	} = useForm<IRefundInfoForm>();
	const { firstName, bank, lastName, phoneNumber } = watch();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (nationality === 'CHN' || nationality === 'HKG') {
			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					nationality: isHkgNation ? 'HKG' : 'CHN',
				},
			});
		}
		reset();
	}, [isHkgNation]);

	//현재 단계, 환급 빈도수 상위 18개국 여부에 따라 버튼 활성화
	useEffect(() => {
		const isActive = Boolean(
			currentStep === targetStep
				? firstName &&
						lastName &&
						phoneNumber &&
						isFirstTermSelected &&
						isSecondTermSelected
				: true
		);
		setIsBtnActive(isActive);
	}, [
		firstName,
		lastName,
		phoneNumber,
		isFirstTermSelected,
		isSecondTermSelected,
	]);

	const { refetch, isFetching } = useQuery<any, string>(
		'RefundInfo',
		() => getRefundInfo(passportNumber),
		{
			retry: false,
			refetchOnWindowFocus: false,
			onSuccess: async (data) => {
				if (!data) {
					return;
				}
				const { accountInfo } = data;
				setValue('firstName', accountInfo.firstName);
				setValue('lastName', accountInfo.lastName);
				setValue('phoneNumber', accountInfo.phoneNumber);
				setValue('transferMethod', accountInfo.transferMethod);
				setValue('aliPayAccount', accountInfo.aliPayAccount);
				setValue('cardNumber', accountInfo.cardNumber);
				setValue('bank', accountInfo.bank);
				setValue('bankAccount', accountInfo.bankAccount);
				setValue('accountInfo', accountInfo.accountInfo);
				setValue('routingNumber', accountInfo.routingNumber);
				setValue('stateProvinceRegion', accountInfo.stateProvinceRegion);
				setValue('zipPostalCode', accountInfo.zipPostalCode);
				setValue('address', accountInfo.address);
				setValue('branchInformation', accountInfo.branchInformation);
				setValue('bsbNumber', accountInfo.bsbNumber);
				setValue('transitNumber', accountInfo.transitNumber);
				setValue('cashPickup', accountInfo.cashPickup);
				setValue('ibanNumber', accountInfo.ibanNumber);
				setValue('swiftCode', accountInfo.swiftCode);
				setValue('birth', accountInfo.birth);
				setValue('addressDetail', accountInfo.addressDetail);
				setValue('addressDetail2', accountInfo.addressDetail2);
				setValue('accountType', accountInfo.accountType);
				setValue('institutionNumber', data.institutionNumber);
				setValue('citizenId', accountInfo.citizenId);
				setValue('country', accountInfo.country);
				setValue('firstNameEng', accountInfo.firstNameEng);
				setValue('lastNameEng', accountInfo.lastNameEng);
				setValue('directInput', accountInfo.directInput);
				setValue('accountHolder', accountInfo.accountHolder);
				setValue('stateProvinceRegionEng', accountInfo.stateProvinceRegionEng);
				setValue('cityEng', accountInfo.cityEng);
				setValue('addressEng', accountInfo.addressEng);
				setValue('addressDetailEng', accountInfo.addressDetailEng);
				setSelectedTransfer(accountInfo.transferMethod);
				setSelectedAccountInfo(accountInfo.accountInfo);
				setSelectedAccountType(accountInfo.accountType);
				setSelectedBank(accountInfo.bank);
				setSelectedGender(accountInfo.gender);
				setIsBankCardInquiry(accountInfo.isBankCardInquiry || false);
				setIsHkgNation(accountInfo.isHkgNation || false);
				await setSelectedRegion(accountInfo.stateProvinceRegion);
				setValue('city', accountInfo.city);
				setSelectedTown(accountInfo.city);
				dispatch({
					type: 'UPDATE_USER_INFO',
					payload: {
						hasNewAnswer: data.hasNewAnswer,
					},
				});
				if (nationality === 'TWN') {
					setSelectedBank(accountInfo.cashPickup);
				}
			},
		}
	);
	const { mutate } = useMutation<IRegisterRefundInfoResponse, AxiosError, any>(
		(payload) => registerRefundInfo(payload),
		{
			retry: false,
			onSuccess: ({ isForTemporaryStorage }) => {
				if (isForTemporaryStorage) return;
				if (isFrequentCountry) {
					if (currentStep !== targetStep) {
						setCurrentStep(currentStep + 1);
						window.scrollTo({ behavior: 'smooth', top: 0 });
					} else {
						history.push(routes.refundResult);
					}
				} else {
					dispatch({
						type: 'UPDATE_HOME_MODAL_STATE',
						payload: {
							type: 'CUSTOM',
							isActive: true,
							data: {
								title: 'Information has been Entered',
								content: `We will send an email within 10 days\nto request the information needed for\nprocessing refunds.\nPlease check your mailbox`,
								btnText: 'OK',
								btnCallback: () => {
									history.push(routes.refundResult);
								},
							},
						},
					});
				}
			},
		}
	);

	const onSubmit = async () => {
		const isChineseNationality = nationality === 'CHN';
		const isStepTwo =
			(isFrequentCountry && currentStep === 2) || !isFrequentCountry;

		let enAddress: IAddressTranslation = {
			stateProvinceRegionEng: '',
			cityEng: '',
			addressEng: '',
			addressDetailEng: '',
		};

		if (isChineseNationality) {
			const { stateProvinceRegion, city, address, addressDetail } = getValues();

			enAddress = (await tanslateChFromToEnAddress(
				stateProvinceRegion,
				city,
				address,
				addressDetail
			)) as IAddressTranslation;
		}

		if (isStepTwo && isChineseNationality) {
			dispatch({
				type: 'UPDATE_HOME_MODAL_STATE',
				payload: {
					isActive: true,
					type: 'CUSTOM',
					data: {
						title: '您输入的名字对吗?',
						content: `${getValues('firstName')}${getValues(
							'lastName'
						)}\n${getValues('firstNameEng')}${getValues('lastNameEng')}`,
						subBtnText: '重新输入',
						btnText: '确认',
						btnCallback: () =>
							mutate({
								passportNumber,
								register: currentStep === targetStep,
								accountInfo: {
									...getValues(),
									gender: selectedGender,
									accountType: selectedAccountType,
									accountInfo: selectedAccountInfo,
									isBankCardInquiry,
									isHkgNation,
									stateProvinceRegionEng: enAddress.stateProvinceRegionEng,
									cityEng: enAddress.cityEng,
									addressEng: enAddress.addressEng,
									addressDetailEng: enAddress.addressDetailEng,
								},
							}),
					},
				},
			});
			return;
		}

		mutate({
			passportNumber,
			register: currentStep === targetStep,
			accountInfo: {
				...getValues(),
				gender: selectedGender,
				accountType: selectedAccountType,
				accountInfo: selectedAccountInfo,
				isBankCardInquiry,
				isHkgNation,
				stateProvinceRegionEng: enAddress.stateProvinceRegionEng,
				cityEng: enAddress.cityEng,
				addressEng: enAddress.addressEng,
				addressDetailEng: enAddress.addressDetailEng,
			},
		});
	};

	const emailCheckAndSubmitForm = () => {
		checkEmailAuthMutate({ email, passportNumber }).then((res) => {
			if (res) {
				setIsVerified(true);
				setIsBtnActive(true);
				handleSubmit(onSubmit)();
			}
		});
	};

	useEffect(() => {
		setValue('aliPayAccount', '');
		setValue('cardNumber', '');
		setValue('bank', '');
		setValue('bankAccount', '');
		setValue('accountInfo', '');
		setValue('routingNumber', '');
		setValue('city', '');
		setValue('stateProvinceRegion', '');
		setValue('zipPostalCode', '');
		setValue('address', '');
		setValue('branchInformation', '');
		setValue('bsbNumber', '');
		setValue('transitNumber', '');
		setValue('cashPickup', '');
		setValue('ibanNumber', '');
		setValue('swiftCode', '');
		setValue('birth', '');
		setValue('addressDetail', '');
		setValue('addressDetail2', '');
		setValue('accountType', '');
		setValue('institutionNumber', '');
		setValue('gender', '');
		setValue('citizenId', '');
		setValue('country', '');
		setValue('firstNameEng', '');
		setValue('lastNameEng', '');
		setValue('accountHolder', '');
		setIsBankCardInquiry(false);
		setSelectedBank('');
		setSelectedRegion('');
		setSelectedTown('');
		setSelectedGender('');
	}, [selectedTransfer, isHkgNation]);

	useEffect(() => {
		const timer = setTimeout(() => {
			refetch();
		}, 400);
		return () => clearTimeout(timer);
	}, []);

	const [isReadPickupGuide, setIsReadPickupGuide] = useState(false);
	const isTwnRead = () => {
		if (nationality === 'TWN') {
			return isReadPickupGuide && !!selectedBank;
		}
		return true;
	};

	return (
		<ContainerWrapper className="notranslate">
			<ReceiptLayout hasPadding={false}>
				<TopSection />
				<Wrapper>
					<RefundAmount />
					{currentStep === 1 && (
						<EmailVerification
							isBankCardInquiry={isBankCardInquiry}
							setIsBtnActive={setIsBtnActive}
							getValues={getValues}
							isHkgNation={isHkgNation}
							isVerified={isVerified}
							setIsVerified={setIsVerified}
						/>
					)}
					{isFrequentCountry &&
						currentStep === 1 &&
						(isFetching ? (
							<LoadingView />
						) : (
							<BankInfoForm
								errors={errors}
								register={register}
								getValues={getValues}
								selectedTransfer={selectedTransfer}
								setSelectedTransfer={setSelectedTransfer}
								selectedAccountInfo={selectedAccountInfo}
								setSelectedAccountInfo={setSelectedAccountInfo}
								selectedBank={selectedBank}
								setSelectedBank={setSelectedBank}
								setValue={setValue}
								selectedAccountType={selectedAccountType}
								setSelectedAccountType={setSelectedAccountType}
								selectedGender={selectedGender}
								setSelectedGender={setSelectedGender}
								isBankCardInquiry={isBankCardInquiry}
								setIsBankCardInquiry={setIsBankCardInquiry}
								selectedRegion={selectedRegion}
								setSelectedRegion={setSelectedRegion}
								selectedTown={selectedTown}
								setSelectedTown={setSelectedTown}
								isHkgNation={isHkgNation}
								setIsHkgNation={setIsHkgNation}
							/>
						))}
					{((isFrequentCountry && currentStep === 2) || !isFrequentCountry) && (
						<RefundInfoForm
							register={register}
							getValues={getValues}
							setValue={setValue}
							errors={errors}
							bankName={bank}
						/>
					)}
					{currentStep === targetStep && (
						<>
							<CheckboxWithLabel
								isTopLine
								isActive={isFirstTermSelected}
								setIsActive={setIsFirstTermSelected}
								label="I will consent to "
								highlightLabel="disclosure of personal information"
							/>
							<CheckboxWithLabel
								isActive={isSecondTermSelected}
								setIsActive={setIsSecondTermSelected}
								label="I agree to "
								highlightLabel="collection and use of personal information"
							/>
						</>
					)}
					{nationality === 'TWN' && currentStep === 1 && (
						<ButtonWrapper>
							<CashPickupGuideCheckbox
								isReadPickupGuide={isReadPickupGuide}
								setIsReadPickupGuide={setIsReadPickupGuide}
							/>
						</ButtonWrapper>
					)}
					<TextButton
						isActive={
							isVerified &&
							isBtnActive &&
							(nationality === 'CHN' ? isBankCardInquiry : true) &&
							isTwnRead()
						}
						onClick={() => {
							if (currentStep === 1 && targetStep === 2) {
								setCurrentStep(currentStep + 1);
							} else {
								isVerified
									? handleSubmit(onSubmit)()
									: emailCheckAndSubmitForm();
							}
						}}
						disabled={
							!isVerified ||
							!isBtnActive ||
							(nationality === 'CHN' && !isBankCardInquiry) ||
							!isTwnRead()
						}
						title={currentStep === targetStep ? 'CHECK' : 'NEXT'}
						margin="20px 0"
					/>
				</Wrapper>
			</ReceiptLayout>
		</ContainerWrapper>
	);
}

export default CustomerRefund;

const ContainerWrapper = styled.div``;

const Wrapper = styled.div`
	width: 335px;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		padding: 0 20px;
	}
`;

const ButtonWrapper = styled.div`
	margin: 10px 0 40px;
`;
