import React from "react";
import { render, hydrate } from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);

// if (rootElement?.hasChildNodes()) {
//   hydrate(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     rootElement,
//     () => {
//       console.log("HYDRATE!!");
//     }
//   );
// } else {
//   render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     rootElement,
//     () => {
//       console.log("RENDERED!!");
//     }
//   );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
