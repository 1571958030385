import Qna from 'components/Faq/Qna';
import FaqTabs from 'components/Faq/FaqTabs';
import { useState } from 'react';
import styled from 'styled-components';
import ICON_EDIT from 'assets/common/icon_edit.png';
import { IGlobalState } from 'store';
import { useAppSelector } from 'hooks/useReduxHooks';
import FaqButton from 'components/Faq/FaqButton';
import MyQna from 'components/Faq/MyQna';
import FaqModal from 'components/Faq/Faq.modal';
import ReceiptLayout from 'components/_common/ReceiptLayout';

function Faq() {
	const { nationality } = useAppSelector((state) => state) as IGlobalState;
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isOpenFaqModal, setIsOpenFaqModal] = useState(false);

	const selectTab = (index: number) => {
		setSelectedIndex(index);
	};
	return (
		<ReceiptLayout hasPadding={false}>
			<FaqTabs value={selectedIndex} onChange={selectTab} />
			<ContentWrapper>
				{selectedIndex === 0 && <Qna />}
				{selectedIndex === 1 && <MyQna />}
			</ContentWrapper>
			<ButtonWrapper>
				<FaqButton
					status="opacity"
					width="100%"
					padding="12px 10px"
					fontSize={16}
					onClick={() => setIsOpenFaqModal(!isOpenFaqModal)}
				>
					<IconEdit src={ICON_EDIT} alt="" />
					{nationality === 'KOR' ? '문의하기' : 'Send inquiry'}
				</FaqButton>
			</ButtonWrapper>
			{isOpenFaqModal && <FaqModal onClose={() => setIsOpenFaqModal(false)} />}
		</ReceiptLayout>
	);
}

export default Faq;

const ContentWrapper = styled.section`
	width: 100%;
	padding-bottom: 100px;
`;

const ButtonWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 375px;
	padding: 40px 20px;
	background: linear-gradient(
		to top,
		rgb(255, 255, 255, 1) 50%,
		rgb(255, 255, 255, 0.5) 70%,
		rgb(255, 255, 255, 0.2) 85%,
		rgba(255, 255, 255, 0)
	);
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		left: 0;
		transform: translateX(0);
	}
`;

const IconEdit = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 6px;
`;
