import { verifyPassport } from 'api';
import LoadingView from 'components/_common/LoadingView';
import { useAppDispatch } from 'hooks/useReduxHooks';
import { IVerifyPassportPayload } from 'model/types';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

type ParamProps = {
	passportNumber: string;
};

function PassportRedirect() {
	const { passportNumber } = useParams() as ParamProps;
	const dispatch = useAppDispatch();
	const history = useHistory();
	const location = useLocation();
	const queryString = new URLSearchParams(location.search);
	const targetPath = queryString.get('path');
	const { mutate: varifiedPassport } = useMutation(
		(payload: IVerifyPassportPayload) => verifyPassport(payload),
		{
			retry: false,
			onSuccess: ({ register, nation, refundAmount, email, paymentStatus }) => {
				nation && localStorage.setItem('customerNation', nation);
				dispatch({
					type: 'UPDATE_USER_INFO',
					payload: {
						passportNumber: passportNumber.toUpperCase(),
						register,
						nationality: nation,
						refundAmount,
						email,
						paymentStatus,
					},
				});
				localStorage.setItem('login', passportNumber);
				history.push(`/${targetPath}`);
			},
		}
	);

	useEffect(() => {
		varifiedPassport({ passportNumber }!);
	}, [passportNumber]);

	return (
		<LoadingWrapper>
			<LoadingView />
		</LoadingWrapper>
	);
}

export default PassportRedirect;

const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;
